import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { links } from '../components/OverlayMenu/OverlayMenu'

const NotFoundPage = () => (
  <Layout color="dark" slug="404">
    <SEO title="404" />
    <section className="section no-padding-bottom">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="page-intro has-text-centered">
              <h1 className="subtitle is-size-2 is-size-3-mobile">404</h1>
              <p className="is-size-4 is-size-5-mobile">Page not found</p>
              <hr />
              <p>
                The page you're looking for doesn't seem to exist! Why not take
                a look at some of these pages instead?
              </p>
              <ul>
                {links.map(l => (
                  <li key={v4()}>
                    <Link to={l.to}>{l.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
